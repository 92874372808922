<template>
  <el-container>
    <el-header height="120px">
      <p class="tableName">
        <i class="el-icon-caret-right"></i>
        草稿箱 - 学员成绩单录入列表
      </p>
    </el-header>
    <el-main>
      <ul>
        <li v-for="(item, index) in list.data" :key="index" @click="goAdd(item.id)">
          {{ draftsListTitle(item) }}
          <i class="el-icon-document-delete fr" @click.stop="deleteDra(item.id)"></i>
        </li>
      </ul>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="list.pageNumber"
        :page-size="list.pageSize"
        layout="prev, pager, next"
        :total="list.total"
      ></el-pagination>
    </el-main>
  </el-container>
</template>
<script>
import http from "@/http/api";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      list: {
        data: [],
        total: 0,
        pageNumber: 1,
        pageSize: 5,
      },
    };
  },
  created() {
    // 获取草稿箱列表
    this.getDraft();
  },
  mounted() {},
  methods: {
    handleCurrentChange(val) {
      this.list.pageNumber = val;
      this.getDraft();
    },
    // 获取草稿箱列表
    async getDraft() {
      const data = await http.getDraft({
        pageNumber: this.list.pageNumber - 1,
        size: this.list.pageSize,
      });

      if (data.data.length && data.data.length > 0) {
        this.list.data = data.data;
        this.list.total = +data.headers["x-total-count"];
      } else {
        this.$message.error({
          message: "暂无数据",
          type: "error",
          duration: 3000,
        });
        this.list.data = [];
        // location.reload();
      }
    },
    goAdd(id) {
      this.$router.push({
        name: "addReport",
        params: {
          id: id,
        },
      });
    },
    // 删除草稿
    deleteDra(id) {
      this.$confirm("确认删除？")
        .then(async (_) => {
          try {
            const { data: data } = await http.deleteDra(id);
            // 获取草稿箱列表
            this.getDraft();
          } catch (error) {
            this.$message.error({
              message: "删除失败,请稍后再试!",
              type: "error",
              duration: 3000,
            });
          }

          // done();
        })
        .catch((_) => {});
    },
  },
  computed: {
    draftsListTitle() {
      return (item) => {
        let school =
          item.schoolName.length > 10
            ? item.schoolName.slice(-11, item.schoolName.length)
            : item.schoolName;
        let grade = item.grade.slice(0, 1);
        // if (item.grade == "一年级") {
        //   grade = "1";
        // } else if (item.grade == "二年级") {
        //   grade = "2";
        // } else if (item.grade == "三年级") {
        //   grade = "3";
        // } else if (item.grade == "四年级") {
        //   grade = "4";
        // } else if (item.grade == "五年级") {
        //   grade = "5";
        // } else if (item.grade == "六年级") {
        //   grade = "6";
        // } else if (item.grade == "初一年级") {
        //   grade = "7";
        // } else if (item.grade == "初二年级") {
        //   grade = "8";
        // } else if (item.grade == "初三年级") {
        //   grade = "9";
        // }
        let classes = "";
        if (item.classes.length == 3) {
          classes = ` (${item.classes.slice(0, 2)}) 班`;
        } else if (item.classes.length == 2) {
          classes = ` (${item.classes.slice(0, 1)}) 班`;
        }
        let course = item.course;
        let userTitle = item.title;
        let examDate =
          item.examDate.slice(0, 4) +
          item.examDate.slice(5, 7) +
          item.examDate.slice(8, 10);
        let examType = item.examType;
        return `${school}${grade}${classes}-${course}${examType}${userTitle} ( ${examDate} ) 成绩单 - 草稿`;
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.el-container {
  height: calc(100vh - 80px);
  overflow: hidden;
  background-color: #fff;
  .el-header {
    text-align: left;
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0px 0px 20px rgba($color: #000000, $alpha: 0.2);
    .tableName {
      height: 100%;
      line-height: 120px;
      padding-left: 44px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #eb6123;
      i {
        width: 20px;
        height: 20px;
        transform: scale3d(1.8, 1.2, 1);
        margin: 0 10px;
        text-shadow: 2px 0 3px #eb6123;
      }
    }
  }
  .el-main {
    background-color: #fff;
    height: 100%;
    min-height: 700px;
    position: relative;
    ul {
      width: 740px;
      padding-left: 60px;
      li {
        text-align: left;
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        border-bottom: 1px solid #eb6123;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        padding-right: 40px;
        i {
          position: absolute;
          top: 20px;
          right: 15px;
          color: #eb6123;
        }
      }
    }
    ::v-deep.el-pagination {
      position: absolute;
      left: 50%;
      bottom: 168px;
      transform: translateX(-50%);
      .el-icon {
        color: #eb6123;
        font-size: 16px;
      }
      .number.active {
        color: #eb6123;
        font-weight: 700;
      }
    }
  }
}
</style>
<style lang="scss">
.el-message-box__wrapper {
  .el-message-box {
    .el-message-box__btns {
      .el-button--primary {
        background-color: #eb6123;
      }
    }
  }
}
</style>
